<template>
  <div fluid class="body">
    <!-- Button Create New Custom Checkout -->
    <div class="d-flex justify-content-end">
      <BaseButton
        :class="{'heaven': isHeaven}"
        variant="primary"
        @click="openConfig('checkout')"
        id="criar_produto"
      >
        {{ $t('seller.checkouts.text_1687') }}
      </BaseButton>
    </div>
    <!-- Loading -->
    <div
      key="loading"
      v-if="loading"
      class="py-5 my-5 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>
    <!-- Number of Found Custom Checkout  -->
    <h5 v-if="!loading && !config && checkouts.length">
      {{ checkouts.length }} {{ $t('seller.checkouts.text_2748') }}
    </h5>
    <!-- Custom Checkout List -->
    <b-row
      v-if="!loading && !config && checkouts.length"
      key="lista"
      class="container"
      data-anima="top"
    >
      <b-col
        cols="12"
        md="6"
        lg="4"
        class="mb-4 mt-4"
        v-for="item in checkouts"
        :key="item.id"
      >
        <div class="checkout">
          <div class="thumb">
            <Preview :item="item"></Preview>
          </div>
          <div class="info">
            <div>
              <p>{{ item.title }}</p>
              <span>{{ $t('seller.checkouts.text_1688') }} {{ item.created_at | moment }}</span>
            </div>
            <b-dropdown
              size="sm"
              right
              dropup
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="float-right drop-personalizado"
            >
              <!-- Dropdown Icon -->
              <template v-slot:button-content>
                <img v-if="!isHeaven" src="@/assets/img/icons/more.svg" class="btn-more-check" />
                <img v-else src="@/assets/img/icons-heaven/more.svg" class="btn-more-check heaven" />
              </template>
              <!-- Edit -->
              <b-dropdown-item @click="editCheckout(item.id)" class="drop-item">
                {{ $t('seller.checkouts.text_1689') }}
              </b-dropdown-item>
              <!-- Delete -->
              <b-dropdown-item
                @click="deleteCheckout(item.id)"
                class="drop-item"
              >
                {{ $t('seller.checkouts.text_1690') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Message of Custom Checkout Not Found -->
    <b-row v-if="!loading && checkouts.length == 0 && !config"
      key="nao-encontrado"
      class="justify-content-center"
    >
      <p class="nao-encontrado">{{ $t('seller.checkouts.text_1691') }}</p>
    </b-row>
    <!-- Config of Selected Custom Checkout -->
    <div v-if="!loading && config" data-anima="top">
      <!-- Name of Custom Checkout -->
      <b-col cols="12" md="6" class="mt-5">
        <b-form-group :label="$t('seller.checkouts.text_1692')" label-for="url" data-anima="top">
          <b-form-input
            v-model="checkout_name"
            type="text"
            :placeholder="$t('seller.checkouts.text_1692')"
            data-form="modificado"
            data-anima="top"
            :class="{'heaven': isHeaven}"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- List of Custom Configs -->
      <b-row key="config" class="container">
        <b-col cols="12" sm="6"  md="4" lg="4" xl="3"
          class="checkout-config my-4 px-2 anime"
          v-for="item in temas"
          :key="item.id"
          @click="activeTheme(item)"
        >
          <span :class="{ active: tema_active === item.id }">
            <img
              :src="require(`@/assets/img/checkout/${item.img}.svg`)"
              class="btn-more"
            />
            <div>
              <p>{{ item.title }}</p>
              <p>{{ item.subtitle }}</p>
            </div>
          </span>
        </b-col>
      </b-row>
      <div v-if="tema_active === 0" data-anima="top">
        <p class="title-config ml-3 mt-4">01 {{ $t('seller.checkouts.text_1693') }}</p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="4" @click="changeBackground('light')">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': cor_fundo === 'light' }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_129') }}</p>
                <span>
                  {{ $t('seller.checkouts.text_130') }}
                </span>
              </div>
            </div>
          </b-col>
          <b-col class="p-2" cols="12" md="4" @click="changeBackground('dark')">
            <div
              class="container-cor-fundo anime tema-escuro-bg"
              :class="{ 'active-item': cor_fundo === 'dark' }"
            >
              <div>
                <div class="block block2">
                  <div></div>
                </div>
                <p class="color-white">{{ $t('seller.checkouts.text_1694') }}</p>
                <span class="color-white">
                  {{ $t('seller.checkouts.text_130') }}
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 0" data-anima="top">
        <p class="title-config ml-3 mt-4">02 {{ $t('seller.checkouts.text_1695') }}</p>
        <b-row class="container config-color">
          <b-col cols="12" md="3" class="p-2 d-flex">
            <div class="color" :style="'background:' + color.background + ';'">
              <span :style="'background:' + color.color + ';'"></span>
            </div>
            <p class="title-config ml-3">
              {{ $t('seller.checkouts.text_1696') }}
            </p>
          </b-col>
          <b-col cols="12" md="8" class="d-flex gap">
            <div
              @click="selectColor(color)"
              class="color anime"
              v-for="color in colors"
              :key="color.id"
              :style="'background:' + color.background + ';'"
            >
              <span :style="'background:' + color.color + ';'"></span>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 1" data-anima="top">
        <p class="title-config ml-3 mt-4">01 {{ $t('seller.checkouts.text_1697') }}</p>
        <div class="recomendado ml-3 mt-2">
          <p class="">
            {{ $t('seller.checkouts.text_132') }}
          </p>
        </div>
        <b-col cols="12" md="12">
          <b-form-group label="" class="img-produto">
            <vue-upload-multiple-image
              :data-images="logotipo"
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @edit-image="editImage"
              idUpload="logotipo"
              editUpload="logotipo"
              :multiple="false"
              dragText="🖼️ Logotipo"
              :browseText="$t('seller.checkouts.text_1698')"
              :showPrimary="false"
              :showEdit="false"
            ></vue-upload-multiple-image>
          </b-form-group>
          <div class="recomendado">
            <p>{{ $t('seller.checkouts.text_1699') }}</p>
            <p>{{ $t('seller.checkouts.text_1700') }}</p>
            <p>{{ $t('seller.checkouts.text_1701') }}</p>
          </div>
        </b-col>
      </div>
      <div v-if="tema_active === 2" data-anima="top">
        <p class="title-config ml-3 mt-4">01 {{ $t('seller.checkouts.text_1702') }}</p>
        <div class="recomendado ml-3 mt-2">
          <p class="">{{ $t('seller.checkouts.text_133') }}</p>
        </div>
        <b-col cols="12" md="12">
          <b-form-group label="" class="img-produto">
            <vue-upload-multiple-image
              :data-images="banner_sup"
              @upload-success="uploadImageSuccessBS"
              @before-remove="beforeRemoveBS"
              @edit-image="editImageBS"
              idUpload="banner_sup"
              editUpload="banner_sup"
              :multiple="false"
              :dragText="$t('seller.checkouts.text_1703')"
              :browseText="$t('seller.checkouts.text_1698')"
              :showPrimary="false"
              :showEdit="false"
            ></vue-upload-multiple-image>
          </b-form-group>
          <div class="recomendado">
            <p>{{ $t('seller.checkouts.text_1699') }}</p>
            <p>{{ $t('seller.checkouts.text_1704') }}</p>
            <p>{{ $t('seller.checkouts.text_1705') }}</p>
          </div>
        </b-col>
      </div>
      <div v-if="tema_active === 3" data-anima="top">
        <p class="title-config ml-3 mt-4">01 {{ $t('seller.checkouts.text_135') }}</p>
        <b-col cols="12" md="12">
          <b-form-group label="" class="img-produto">
            <vue-upload-multiple-image
              :data-images="banner_rod"
              @upload-success="uploadImageSuccessBR"
              @before-remove="beforeRemoveBR"
              @edit-image="editImageBR"
              idUpload="banner_rod"
              editUpload="banner_rod"
              :multiple="false"
              dragText="🖼️ Banner Rodapé"
              :browseText="$t('seller.checkouts.text_1698')"
              :showPrimary="false"
              :showEdit="false"
            ></vue-upload-multiple-image>
          </b-form-group>
          <div class="recomendado">
            <p>{{ $t('seller.checkouts.text_1699') }}</p>
            <p>{{ $t('seller.checkouts.text_1704') }}</p>
            <p>{{ $t('seller.checkouts.text_1705') }}</p>
          </div>
        </b-col>
      </div>
      <div v-if="tema_active === 4" data-anima="top">
        <p class="title-config ml-3 mt-4">01 {{ $t('seller.checkouts.text_136') }}</p>
        <b-col cols="12" md="12">
          <b-form-group label="" class="img-produto">
            <vue-upload-multiple-image
              :data-images="banner_lat"
              @upload-success="uploadImageSuccessBL"
              @before-remove="beforeRemoveBL"
              @edit-image="editImageBL"
              idUpload="banner_lat"
              editUpload="banner_lat"
              :multiple="false"
              dragText="🖼️ Banner Lateral"
              :browseText="$t('seller.checkouts.text_1698')"
              :showPrimary="false"
              :showEdit="false"
            ></vue-upload-multiple-image>
          </b-form-group>
          <div class="recomendado">
            <p>{{ $t('seller.checkouts.text_1699') }}</p>
            <p>{{ $t('seller.checkouts.text_1706') }}</p>
            <p>{{ $t('seller.checkouts.text_1707') }}</p>
          </div>
        </b-col>
      </div>
      <div v-if="tema_active === 16" data-anima="top">
        <p class="title-config ml-3 mt-4">01 {{ $t('seller.checkouts.text_136') }}</p>
        <b-col cols="12" md="12">
          <b-form-group label="" class="img-produto">
            <vue-upload-multiple-image
              :data-images="banner_lat_mobile"
              @upload-success="uploadImageSuccessBLM"
              @before-remove="beforeRemoveBLM"
              @edit-image="editImageBLM"
              idUpload="banner_lat_mobile"
              editUpload="banner_lat_mobile"
              :multiple="false"
              dragText="🖼️ Banner Lateral Mobile"
              :browseText="$t('seller.checkouts.text_1698')"
              :showPrimary="false"
              :showEdit="false"
            ></vue-upload-multiple-image>
          </b-form-group>
          <div class="recomendado">
            <p>{{ $t('seller.checkouts.text_1699') }}</p>
            <p>{{ $t('seller.checkouts.text_1706') }}</p>
            <p>{{ $t('seller.checkouts.text_1707') }}</p>
          </div>
        </b-col>
      </div>
      <div v-if="tema_active === 5" data-anima="top">
        <p class="title-config ml-3 mt-4">
          01 {{ $t('seller.checkouts.text_137') }}
        </p>
        <b-col cols="12" md="5">
          <b-form-group label="" class="texto-botao">
            <b-form-input
              name="texto-botao"
              id="texto-botao"
              :placeholder="$t('seller.checkouts.text_1708')"
              v-model="button_text"
              type="text"
              autocomplete="off"
              maxlength="35"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
          </b-form-group>

          <div v-if="button_text" data-anima="bottom" class="exemplo-btn">
            <p>{{ $t('seller.checkouts.text_1709') }}</p>
            <BaseButton
              variant="black"
              :style="'background:' + color.color + ';'"
              >{{ button_text }}</BaseButton
            >
          </div>
        </b-col>
      </div>
      <div v-if="tema_active === 6" data-anima="top">
        <span class="title-config destaque ml-3 mt-5"
          >{{ $t('seller.checkouts.text_1710') }}</span
        >
        <p class="title-config ml-3 mt-4">01 {{ $t('seller.checkouts.text_139') }}</p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="4" @click="trial_position = 'top'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': trial_position === 'top' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('top')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_1711') }}</p>
                <span>
                  {{ $t('seller.checkouts.text_140') }}
                </span>
              </div>
            </div>
          </b-col>

          <b-col class="p-2" cols="12" md="4" @click="trial_position = 'bottom'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': trial_position === 'bottom' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_2749') }}</p>
                <span>
                  {{ $t('seller.checkouts.text_141') }}
                </span>
              </div>
            </div>
          </b-col>
        </b-row>

        <p class="title-config ml-3 mt-4">02 {{ $t('seller.checkouts.text_142') }}</p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="4" @click="trial_info = 'total'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': trial_info === 'total' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('top')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_1713') }}</p>
                <span>
                  {{ $t('seller.checkouts.text_143') }}
                </span>
              </div>
            </div>
          </b-col>

          <b-col class="p-2" cols="12" md="4" @click="trial_info = 'fixa'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': trial_info === 'fixa' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_144') }}</p>
                <span>
                  {{ $t('seller.checkouts.text_145') }}
                </span>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="container">
          <b-col cols="12" class="exemplo-btn">
            <p>{{ $t('seller.checkouts.text_146') }}</p>
            <b-badge variant="success">{{
              trial_info == "total"
                ? $t('seller.checkouts.text_147')
                : $t('seller.checkouts.text_148')
            }}</b-badge>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 7" data-anima="top">
        <p class="title-config ml-3 mt-4">
          01 {{ $t('seller.checkouts.text_1714') }}
        </p>
        <b-row class="container">
          <b-col cols="12" md="5">
            <b-form-group label="" class="widget-id">
              <b-form-input
                name="widget-id"
                id="widget-id"
                :placeholder="$t('seller.checkouts.text_149')"
                v-model="jivochat_id"
                type="text"
                autocomplete="off"
                maxlength="15"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
            </b-form-group>
            <div class="recomendado">
              <p>
                {{ $t('seller.checkouts.text_150') }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 8" data-anima="top">
        <p class="title-config ml-3 mt-4">
          01 {{ $t('seller.checkouts.text_151') }}
        </p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="4" @click="step_checkout = 'default'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': step_checkout === 'default' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('top')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>03 {{ $t('seller.checkouts.text_152') }}</p>
                <span>
                  {{ $t('seller.checkouts.text_153') }}
                </span>
              </div>
            </div>
          </b-col>

          <b-col class="p-2" cols="12" md="4" @click="step_checkout = 'one_step'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': step_checkout === 'one_step' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>01 {{ $t('seller.checkouts.text_1715') }}</p>
                <span>
                  {{ $t('seller.checkouts.text_154') }}</span
                >
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 9" data-anima="top">
        <p class="title-config ml-3 mt-4">
          01 {{ $t('seller.checkouts.text_155') }}
        </p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="4" @click="notification = 'off'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': notification === 'off' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('top')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_156') }}</p>
                <span> {{ $t('seller.checkouts.text_157') }} </span>
              </div>
            </div>
          </b-col>

          <b-col class="p-2" cols="12" md="4" @click="notificationON('b-toaster-top-right')"
          >
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': notification === 'on' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_159') }}</p>
                <span>
                  {{ $t('seller.checkouts.text_158') }}
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 9 && notification === 'on'" data-anima="top">
        <p class="title-config ml-3 mt-4">
          02 {{ $t('seller.checkouts.text_160') }}
        </p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="4" @click="notification_type = 'quantity'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': notification_type === 'quantity' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('top')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_161') }}</p>
                <span>
                  {{ $t('seller.checkouts.text_162') }}
                </span>
              </div>
            </div>
          </b-col>

          <b-col class="p-2" cols="12" md="4" @click="notification_type = 'time'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': notification_type === 'time' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_163') }}</p>
                <span>
                  {{ $t('seller.checkouts.text_164') }}
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
        <p class="title-config ml-3 mt-4">
          03 {{ $t('seller.checkouts.text_165') }}
          {{ notification_type === "time" ? $t('seller.checkouts.text_2750') : $t('seller.checkouts.text_2751') }}
        </p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="5">
            <b-form-group label="" class="widget-id">
              <b-form-input
                name="widget-id"
                id="widget-id"
                placeholder="Ex: 50"
                v-model="notification_quantity"
                type="number"
                autocomplete="off"
                maxlength="3"
                v-validate="'required'"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('widget-id')">
                {{ $t('seller.checkouts.text_1716') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <p class="title-config ml-3 mt-4">
          04 {{ $t('seller.checkouts.text_166') }}
        </p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="5">
            <b-form-group label="" class="widget-id">
              <div class="d-flex mt-2" style="gap: 30px">
                <div>
                  <b-form-input
                    name="notification_interval-1"
                    placeholder="Ex: 15"
                    v-model="notification_interval[0]"
                    type="number"
                    v-mask="'##'"
                    min="15"
                    maxlength="2"
                    v-validate="'required'"
                    :class="{'heaven': isHeaven}"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('notification_interval-1')"
                  >
                    {{ $t('seller.checkouts.text_1716') }}
                  </b-form-invalid-feedback>
                </div>

                <div>
                  <b-form-input
                    name="notification_interval-2"
                    placeholder="Ex: 60"
                    v-model="notification_interval[1]"
                    type="number"
                    v-mask="'##'"
                    max="60"
                    maxlength="2"
                    v-validate="'required'"
                    :class="{'heaven': isHeaven}"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('notification_interval-2')"
                  >
                    {{ $t('seller.checkouts.text_1716') }}
                  </b-form-invalid-feedback>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <p class="title-config ml-3 mt-4">
          05 {{ $t('seller.checkouts.text_167') }}
        </p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="4" @click="notificationON('b-toaster-top-right')">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{
                'active-item': notification_position === 'b-toaster-top-right',
              }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_1717') }}</p>
              </div>
            </div>
          </b-col>
          <b-col class="p-2" cols="12" md="4" @click="notificationON('b-toaster-top-center')">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{
                'active-item': notification_position === 'b-toaster-top-center',
              }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_1718') }}</p>
              </div>
            </div>
          </b-col>
          <b-col class="p-2" cols="12" md="4" @click="notificationON('b-toaster-top-left')">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{
                'active-item': notification_position === 'b-toaster-top-left',
              }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_1719') }}</p>
              </div>
            </div>
          </b-col>
          <b-col class="p-2" cols="12" md="4" @click="notificationON('b-toaster-bottom-right')">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{
                'active-item':
                  notification_position === 'b-toaster-bottom-right',
              }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_1720') }}</p>
              </div>
            </div>
          </b-col>
          <b-col class="p-2" cols="12" md="4" @click="notificationON('b-toaster-bottom-center')">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{
                'active-item':
                  notification_position === 'b-toaster-bottom-center',
              }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_1721') }}</p>
              </div>
            </div>
          </b-col>
          <b-col class="p-2" cols="12" md="4" @click="notificationON('b-toaster-bottom-left')">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{
                'active-item':
                  notification_position === 'b-toaster-bottom-left',
              }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_1722') }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 10" data-anima="top">
        <p class="title-config ml-3 mt-4">01 {{ $t('seller.checkouts.text_168') }}</p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="5">
            <b-form-group label="" label-for="scarcity ">
              <b-form-checkbox
                v-model="scarcity"
                name="scarcity"
                size="lg"
                switch
              >
                <p class="info-checkbox">
                  {{ $t('seller.checkouts.text_1723') }} {{ scarcity ? $t('seller.checkouts.text_1724') : $t('seller.checkouts.text_1725') }}
                  <span class="descricao-checkbox">
                    {{ $t('seller.checkouts.text_169') }}
                  </span>
                </p>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col class="p-2" cols="12" md="7" v-if="scarcity" data-anima="right">
            <div
              class="exemplo-escassez"
              :style="'background:' + scarcity_background_color + ';'"
            >
              <div class="flex-escassez">
                <img src="@/assets/img/icons/escassez.svg" />
                <div>
                  <h5>{{ scarcity_title || $t('seller.checkouts.text_1726') }}</h5>
                  <p>
                    {{ scarcity_subtitle || $t('seller.checkouts.text_1727') }}
                  </p>
                </div>
              </div>

              <h5 class="timer">01:32:27</h5>
            </div>
          </b-col>
        </b-row>

        <div v-if="scarcity" data-anima="top">
          <p class="title-config ml-3 mt-4">02 {{ $t('seller.checkouts.text_170') }}</p>
          <b-row class="container">
            <b-col cols="12" md="5">
              <b-form-group label="" class="scarcity_title">
                <b-form-input
                  name="scarcity_title"
                  id="scarcity_title"
                  :placeholder="$t('seller.checkouts.text_1728')"
                  v-model="scarcity_title"
                  type="text"
                  autocomplete="scarcity_title"
                  maxlength="27"
                  v-validate="'required'"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('scarcity_title')">
                  {{ $t('seller.checkouts.text_1729') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <p class="title-config ml-3 mt-4">03 {{ $t('seller.checkouts.text_171') }}</p>
          <b-row class="container">
            <b-col cols="12" md="5">
              <b-form-group label="" class="scarcity_subtitle">
                <b-form-input
                  name="scarcity_subtitle"
                  id="scarcity_subtitle"
                  :placeholder="$t('seller.checkouts.text_1728')"
                  v-model="scarcity_subtitle"
                  type="text"
                  autocomplete="scarcity_subtitle"
                  maxlength="47"
                  v-validate="'required'"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="!errors.has('scarcity_subtitle')"
                >
                  {{ $t('seller.checkouts.text_1730') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <p class="title-config ml-3 mt-4">
            04 {{ $t('seller.checkouts.text_172') }}
          </p>
          <b-row class="container">
            <b-col cols="12" md="5" id="minutos-escassez">
              <b-form-group label="" class="scarcity_time">
                <b-form-input
                  id="scarcity_time"
                  name="scarcity_time"
                  v-model="scarcity_time"
                  placeholder="Ex: mm"
                  type="number"
                  v-mask="['##']"
                  maxlength="60"
                  autocomplete="scarcity_time"
                  v-validate="'required|date_format:mm|min_value:1|max_value:60'"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('scarcity_time')">
                  {{ $t('seller.checkouts.text_173') }}
                </b-form-invalid-feedback>
                <div class="recomendado">
                  <p>{{ $t('seller.checkouts.text_1731') }}</p>
                </div>
              </b-form-group>
            </b-col>
            <b-tooltip
              target="minutos-escassez"
              :title="$t('seller.checkouts.text_1732')"
            />
          </b-row>

          <p class="title-config ml-3 mt-4">
            05 {{ $t('seller.checkouts.text_174') }}
          </p>
          <b-row class="container config-color">
            <b-col cols="12" md="3" class="p-2 d-flex">
              <div
                class="color"
                :style="'background:' + scarcity_background_color + ';'"
              ></div>
              <p class="title-config ml-3">{{ $t('seller.checkouts.text_1733') }}</p>
            </b-col>
            <b-col cols="12" md="8" class="d-flex gap">
              <div
                @click="selectColorEscassez(color)"
                class="color anime"
                v-for="color in colors"
                :key="color.id"
                :style="'background:' + color.color + ';'"
              ></div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="tema_active === 11" data-anima="top">
        <p class="title-config ml-3 mt-4">01 {{ $t('seller.checkouts.text_175') }}</p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="5">
            <b-form-group label="" label-for="scarcity ">
              <b-form-checkbox
                v-model="ob_custom"
                name="scarcity"
                size="lg"
                switch
              >
                <p class="info-checkbox">
                  {{ $t('seller.checkouts.text_175') }}
                  {{ ob_custom ? $t('seller.checkouts.text_1734') : $t('seller.checkouts.text_1735') }}
                  <span class="descricao-checkbox"
                    >{{ $t('seller.checkouts.text_176') }}</span
                  >
                </p>
              </b-form-checkbox>
            </b-form-group>
            <div v-if="ob_custom" data-anima="top">
              <p class="title-config mt-4">02 {{ $t('seller.checkouts.text_177') }}</p>
              <b-row class="container">
                <b-col cols="12" md="12" class="not_padding">
                  <b-form-group label="" class="ob_title">
                    <b-form-input
                      name="ob_title"
                      id="ob_title"
                      :placeholder="`Ex: ${$t('seller.checkouts.text_178')}`"
                      v-model="ob_title"
                      type="text"
                      autocomplete="ob_title"
                      maxlength="50"
                      v-validate="'required'"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('ob_title')">
                      {{ $t('seller.checkouts.text_1729') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <p class="title-config mt-4">03 {{ $t('seller.checkouts.text_179') }}</p>
              <b-row class="container">
                <b-col cols="12" md="12" class="not_padding">
                  <b-form-group label="" class="ob_subtitle">
                    <b-form-input
                      name="ob_subtitle"
                      id="ob_subtitle"
                      :placeholder="$t('seller.checkouts.text_1736')"
                      v-model="ob_subtitle"
                      type="text"
                      autocomplete="ob_subtitle"
                      maxlength="27"
                      v-validate="'required'"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :state="!errors.has('ob_subtitle')"
                    >
                      {{ $t('seller.checkouts.text_1737') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <p class="title-config mt-4">04 {{ $t('seller.checkouts.text_180') }}</p>
              <b-row class="container">
                <b-col cols="12" md="12" class="not_padding">
                  <b-form-group label="" class="ob_title">
                    <b-form-checkbox
                      v-model="ob_description"
                      name="scarcity"
                      size="lg"
                      switch
                    >
                      <p class="info-checkbox">
                        {{ $t('seller.checkouts.text_180') }}
                        {{ ob_description ? $t('seller.checkouts.text_1734') : $t('seller.checkouts.text_1735') }}
                        <span class="descricao-checkbox"
                          >{{ $t('seller.checkouts.text_181') }}</span
                        >
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col class="p-2" cols="12" md="7" v-if="ob_custom" data-anima="right">
            <p class="titleBump">
              {{ ob_title }}
            </p>
            <div class="order-bump" @click="checkbox = !checkbox">
              <div
                class="header"
                :style="`background:${ob_background_color.color};`"
              >
                <div>
                  <b-form-checkbox
                    style="position: relative; top: 15px"
                    v-model="checkbox"
                  >
                    <p class="ml-2 info-value">
                      {{ ob_subtitle }}
                    </p>
                  </b-form-checkbox>
                </div>
                <p class="info-value" style="position: relative; top: 15px">
                  R$ 600,00
                </p>
              </div>
              <div
                class="base"
                :style="
                  ob_border !== 'off'
                    ? ob_border === 'pontilhada'
                      ? `border-left: ${ob_border_px}px dashed ${ob_background_color.color}; border-right: ${ob_border_px}px dashed ${ob_background_color.color}; border-bottom: ${ob_border_px}px dashed ${ob_background_color.color}; background:${ob_background_color.background};`
                      : `border-bottom:  ${ob_border_px}px solid ${ob_background_color.color}; border-right:  ${ob_border_px}px solid ${ob_background_color.color}; border-left:  ${ob_border_px}px solid ${ob_background_color.color}; background:${ob_background_color.background};`
                    : `border:none; background:${ob_background_color.background};`
                "
              >
                <div class="img">
                  <img src="@/assets/logo-app.jpeg" alt="logo" />
                </div>
                <div>
                  <p class="name">{{ $t('seller.checkouts.text_2752') }}</p>
                  <p
                    class="value"
                    :style="`color:${ob_background_color.color};`"
                  >
                    R$ 600,00
                  </p>
                  <p class="description" v-if="ob_description">
                    {{ $t('seller.checkouts.text_182') }}
                  </p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <div v-if="ob_custom" data-anima="top">
          <p class="title-config ml-3 mt-4">05 {{ $t('seller.checkouts.text_183') }}</p>
          <b-row class="container">
            <b-col class="p-2" cols="12" md="4" @click="ob_border = 'off'">
              <div
                class="container-cor-fundo mb-4 anime"
                :class="{ 'active-item': ob_border === 'off' }"
              >
                <!-- v-b-tooltip.hover.html="imageItem('top')" -->
                <div>
                  <div class="block">
                    <div></div>
                  </div>
                  <p>{{ $t('seller.checkouts.text_184') }}</p>
                  <span> </span>
                </div>
              </div>
            </b-col>

            <b-col class="p-2" cols="12" md="4" @click="ob_border = 'lisa'">
              <div
                class="container-cor-fundo mb-4 anime"
                :class="{ 'active-item': ob_border === 'lisa' }"
              >
                <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
                <div>
                  <div class="block">
                    <div></div>
                  </div>
                  <p>{{ $t('seller.checkouts.text_185') }}</p>
                  <span></span>
                </div>
              </div>
            </b-col>
            <b-col class="p-2" cols="12" md="4" @click="ob_border = 'pontilhada'">
              <div
                class="container-cor-fundo mb-4 anime"
                :class="{ 'active-item': ob_border === 'pontilhada' }"
              >
                <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
                <div>
                  <div class="block">
                    <div></div>
                  </div>
                  <p>{{ $t('seller.checkouts.text_186') }}</p>
                  <span></span>
                </div>
              </div>
            </b-col>
            <p v-if="ob_border !== 'off'" class="title-config ml-3 mt-2">
              {{ $t('seller.checkouts.text_187') }}
            </p>
            <b-row class="container" v-if="ob_border !== 'off'">
              <b-col class="p-2" cols="12" md="4">
                <b-form-group label="" class="ob_border_px">
                  <b-form-input
                    name="ob_border_px"
                    id="ob_border_px"
                    placeholder="Ex: 4"
                    v-model="ob_border_px"
                    type="number"
                    min="1"
                    max="15"
                    v-mask="'##'"
                    v-validate="'required'"
                    :class="{'heaven': isHeaven}"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('ob_border_px')">
                    {{ $t('seller.checkouts.text_1738') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-row>
          <p class="title-config ml-3 mt-4">06 {{ $t('seller.checkouts.text_188') }}</p>
          <b-row class="container config-color">
            <b-col cols="12" md="3" class="p-2 d-flex">
              <div
                class="color"
                :style="'background:' + ob_background_color.color + ';'"
              ></div>
              <p class="title-config ml-3">{{ $t('seller.checkouts.text_189') }}</p>
            </b-col>
            <b-col cols="12" md="8" class="p-2 d-flex gap">
              <div
                class="color anime"
                v-for="color in colors2"
                :key="color.id"
                :style="'background:' + color.color + ';'"
                @click="ob_background_color = color"
              ></div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="tema_active === 12" data-anima="top">
        <p class="title-config ml-3 mt-4">
          01 {{ $t('seller.checkouts.text_190') }}
        </p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="4" @click="warranty_checkout = 'off'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': warranty_checkout === 'off' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('top')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_192') }}</p>
                <span>
                  {{ $t('seller.checkouts.text_191') }}
                </span>
              </div>
            </div>
          </b-col>

          <b-col class="p-2" cols="12" md="4" @click="warranty_checkout = 'on'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': warranty_checkout === 'on' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>{{ $t('seller.checkouts.text_193') }}</p>
                <span>
                  {{ $t('seller.checkouts.text_194') }}
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 13" data-anima="top">
        <p class="title-config ml-3 mt-4">01 {{ $t('seller.checkouts.text_195') }}</p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="5">
            <b-form-group label="" label-for="scarcity ">
              <b-form-checkbox
                v-model="whatsapp_button"
                name="scarcity"
                size="lg"
                switch
              >
                <p class="info-checkbox">
                  {{ $t('seller.checkouts.text_206') }}:
                  {{ whatsapp_button ? $t('seller.checkouts.text_207') : $t('seller.checkouts.text_208') }}
                  <span class="descricao-checkbox">
                    {{ $t('seller.checkouts.text_209') }}
                  </span>
                </p>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <p class="title-config ml-3 mt-4" v-if="whatsapp_button">
          02 {{ $t('seller.checkouts.text_195') }}
        </p>
        <b-row class="container" v-if="whatsapp_button">
          <b-col class="p-2" cols="12" md="5">
            <b-form-group label="" class="">
              <VuePhoneNumberInput
                v-model="whatsapp"
                :translations="$i18n.locale === 'pt' ? translations_pt : (
                  $i18n.locale === 'es' ? translations_es : translations_en
                )"
                error-color="#ff0c37"
                size="lg"
                :default-country-code="whatsapp_number ? wpp_country : 'BR'"
                :preferred-countries="['BR', 'PT', 'US', 'FR', 'ES']"
                ref="telinput"
                :required="true"
                :countries-height="50"
                :border-radius="5"
                :class="{'heaven': isHeaven}"
                @update="onUpdate"
              />
              <b-form-invalid-feedback
                class="mt-3"
                :state="submit ? results_tel.isValid : null">
                {{ $t('seller.checkouts.text_1739') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <div class="recomendado">
              <p>
                {{ $t('seller.checkouts.text_196') }}
              </p>
            </div>
          </b-col>
        </b-row>
        <p class="title-config ml-3 mt-4" v-if="whatsapp_button">
          03 {{ $t('seller.checkouts.text_197') }}
        </p>
        <b-row class="container" v-if="whatsapp_button">
          <b-col class="p-2" cols="12" md="12">
            <b-form-group label="">
              <b-form-textarea
                name="whatsapp_msg"
                id="whatsapp_msg"
                :placeholder="$t('seller.checkouts.text_198')"
                v-model="whatsapp_msg"
                autocomplete="off"
                size="lg"
                no-resize
                :class="{'heaven': isHeaven}"
              ></b-form-textarea>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('whatsapp_number') : null">
                {{ $t('seller.checkouts.text_1740') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <div class="recomendado">
              <p>{{ $t('seller.checkouts.text_199') }}</p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 14" data-anima="top">
        <p class="title-config ml-3 mt-4">01 {{ $t('seller.checkouts.text_204') }}</p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="5">
            <b-form-group label="" label-for="scarcity ">
              <b-form-checkbox
                v-model="confirmation_email"
                name="scarcity"
                size="lg"
                switch
              >
                <p class="info-checkbox">
                  {{ $t('seller.checkouts.text_2777') }}:
                  {{ confirmation_email ? "ativado" : "desativado" }}
                  <span class="descricao-checkbox"
                    >{{ $t('seller.checkouts.text_205') }}</span
                  >
                </p>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <p class="title-config ml-3 mt-4">
          01 Adicione o seu Widget ID do Zopim
        </p>
        <b-row class="container">
          <b-col cols="12" md="5">
            <b-form-group label="" class="widget-id">
              <b-form-input
                name="widget-id"
                id="widget-id"
                placeholder="Aqui você deve colocar o ID do seu Widget"
                v-model="zopim_id"
                type="text"
                autocomplete="off"
                maxlength="40"
              ></b-form-input>
            </b-form-group>
            <div class="recomendado">
              <p>
                Com base no seu Widget ID, iremos adicionar o Zopim neste
                checkout
              </p>
            </div>
          </b-col>
        </b-row> -->
      </div>

      <div v-if="tema_active === 15" data-anima="top">
        <p class="title-config ml-3 mt-4">01 {{ $t('seller.checkouts.text_2779') }}</p>
        <b-row class="container">
          <b-col class="p-2" cols="12" md="5">
            <b-form-group label="" label-for="scarcity ">
              <b-form-checkbox
                v-model="exit_pop_up "
                name="scarcity"
                size="lg"
                switch              >
                <p class="info-checkbox">
                  {{ $t('seller.checkouts.text_2780') }}
                  {{ exit_pop_up  ? "ativado" : "desativado" }}
                  <span class="descricao-checkbox">{{ $t('seller.checkouts.text_2781') }}</span>
                </p>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

        <p class="title-config ml-3 mt-4" v-if="exit_pop_up">
          02 {{ $t('seller.checkouts.text_2782') }}
        </p>
        <b-row class="container" v-if="exit_pop_up">
          <b-col class="p-2" cols="12" md="12">
            <b-form-group label="" class="img-produto">
              <vue-upload-multiple-image
                :data-images="image_exit_pop_up"
                @upload-success="uploadImageSuccessExitPopUp"
                @before-remove="beforeRemoveExitPopUp"
                @edit-image="editImageExitPopUp"
                idUpload="image_exit_pop_up"
                editUpload="image_exit_pop_up"
                :multiple="false"
                dragText="🖼️ Imagem do Pop Up"
                :browseText="$t('seller.checkouts.text_1698')"
                :showPrimary="false"
                :showEdit="false">
              </vue-upload-multiple-image>
            </b-form-group>
          </b-col>
        </b-row>

        <p class="title-config ml-3 mt-4" v-if="exit_pop_up">
          03 {{ $t('seller.checkouts.text_2785') }}
        </p>
        <b-row class="container" v-if="exit_pop_up">
          <b-col class="p-2" cols="12" md="5">
            <b-form-group label="" label-for="scarcity ">
              <b-form-checkbox
                v-model="button_exit_pop_up "
                name="scarcity"
                size="lg"
                switch
              >
                <p class="info-checkbox">
                  {{ $t('seller.checkouts.text_2785') }}
                  {{ button_exit_pop_up  ? "ativado" : "desativado" }}
                  <!-- <span class="descricao-checkbox">{{ $t('seller.checkouts.text_205') }}</span> -->
                  <span class="descricao-checkbox">{{ $t('seller.checkouts.text_2786') }}</span>
                </p>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

        <p class="title-config ml-3 mt-4" v-if="exit_pop_up && button_exit_pop_up">
          03.1 {{ $t('seller.checkouts.text_2787') }}
        </p>
        <b-row class="container" v-if="exit_pop_up && button_exit_pop_up">
          <b-col class="p-2" cols="12" md="12">
            <b-form-group label="">
              <b-form-input
                name="text_button_exit_pop_up"
                :placeholder="$t('seller.checkouts.text_2788')"
                id="text_button_exit_pop_up"
                v-model="text_button_exit_pop_up"
                type="text"
                maxlength="50"
                v-validate="button_exit_pop_up ? 'required' : null">
              </b-form-input>
              <b-form-invalid-feedback
                :state="button_exit_pop_up ? !errors.has('text_button_exit_pop_up') : null">
                {{ $t('seller.checkouts.text_2789') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <p class="title-config ml-3 mt-4" v-if="exit_pop_up">
          04 {{ $t('seller.checkouts.text_2783') }}
        </p>
        <b-row class="container" v-if="exit_pop_up">
          <b-col class="p-2" cols="12" md="12">
            <b-form-group label="" class="ob_title">
              <b-form-input
                name="title_exit_pop_up"
                id="title_exit_pop_up"
                v-model="title_exit_pop_up"
                type="text"
                maxlength="50"
                :placeholder="$t('seller.checkouts.text_2784')"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('title_exit_pop_up') : null">
                <!-- {{ $t('seller.checkouts.text_1729') }} -->
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <p class="title-config ml-3 mt-4" v-if="exit_pop_up">
          05 {{ $t('seller.checkouts.text_2790') }}
        </p>
        <b-row class="container" v-if="exit_pop_up">
          <b-col class="p-2" cols="12" md="12">
            <b-form-group label="">
              <b-form-textarea
                name="link_exit_pop_up"
                id="link_exit_pop_up"
                :placeholder="$t('seller.checkouts.text_2791')"
                v-model="link_exit_pop_up"
                autocomplete="off"
                size="lg"
                no-resize
                :class="{'heaven': isHeaven, 'is-invalid': !isLinkValid}"
                @input="linkExitPopUpChanged = true"
              ></b-form-textarea>
              <b-form-invalid-feedback v-if="linkExitPopUpChanged && !isLinkValid">
                {{ $t('seller.checkouts.text_2792') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <p class="title-config ml-3 mt-4" v-if="exit_pop_up">
          06 {{ $t('seller.checkouts.text_2793') }}
        </p>
        <b-row class="container" v-if="exit_pop_up">
          <b-col class="p-2" cols="12" md="12">
            <b-form-group label="">
              <b-form-textarea
                name="description_exit_pop_up"
                id="description_exit_pop_up"
                :placeholder="$t('seller.checkouts.text_2794')"
                v-model="description_exit_pop_up"
                autocomplete="off"
                size="lg"
                no-resize
                maxlength="100"
                :class="{'heaven': isHeaven}"
                @input="updateDescriptionExitPopUpLength"
              ></b-form-textarea>
              <div class="pl-2" style="font-size: 13px; ">{{ descriptionExitPopUpLength }}/100 {{ $t('seller.checkouts.text_2795') }} </div>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('description_exit_pop_up') : null">
                <!-- {{ $t('seller.checkouts.text_1740') }} -->
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <div class="footer d-flex justify-content-end">
        <BaseButton
          variant="link-info"
          :disabled="loading"
          @click="cancel"
          class="mr-4 mt-4 mt-md-0"
        >
          {{ $t('seller.checkouts.text_1741') }}
        </BaseButton>
        <BaseButton
          v-if="edit === null"
          variant="primary"
          class="mt-4 mt-md-0"
          :class="{'heaven': isHeaven}"
          :disabled="loading"
          @click="onSubmit"
        >
          {{ $t('seller.checkouts.text_1742') }}
        </BaseButton>
        <BaseButton
          v-else
          variant="primary"
          class="mt-4 mt-md-0"
          :class="{'heaven': isHeaven}"
          :disabled="loading"
          @click="onSubmitUpdate"
        >
          {{ $t('seller.checkouts.text_1742') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import moment from "moment-timezone";
import CheckoutService from "@/services/resources/CheckoutService";
const service = CheckoutService.build();
import Preview from "@/components/Seller/Checkout/preview";
export default {
  name: 'BaseCheckout',
  components: {
    VuePhoneNumberInput,
    VueUploadMultipleImage,
    Preview,
  },
  data() {
    return {
      results_tel: {
        isValid: false,
      },
      whatsapp_button: false,
      submit: false,
      loading: false,
      config: false,
      configUpsell: false,
      edit: null,
      title: "Checkouts",
      client: {
        width: 0,
      },
      temas: [
        { id: 0, title: this.$t('seller.checkouts.text_2753'), subtitle: this.$t('seller.checkouts.text_2764'), img: "tema0" },
        { id: 1, title: this.$t('seller.checkouts.text_2753'), subtitle: this.$t('seller.checkouts.text_2765'), img: "tema1" },
        { id: 2, title: this.$t('seller.checkouts.text_2754'), subtitle: this.$t('seller.checkouts.text_2766'), img: "tema2" },
        { id: 3, title: this.$t('seller.checkouts.text_2754'), subtitle: this.$t('seller.checkouts.text_2767'), img: "tema3" },
        { id: 4, title: this.$t('seller.checkouts.text_2754'), subtitle: this.$t('seller.checkouts.text_2768'), img: "tema4" },
        { id: 16, title: this.$t('seller.checkouts.text_2754'), subtitle: this.$t('seller.checkouts.text_side_mobile'), img: "tema4" },
        { id: 5, title: this.$t('seller.checkouts.text_2755'), subtitle: this.$t('seller.checkouts.text_2769'), img: "tema1" },
        { id: 6, title: this.$t('seller.checkouts.text_2756'), subtitle: this.$t('seller.checkouts.text_2770'), img: "tema0" },
        { id: 7, title: this.$t('seller.checkouts.text_2757'), subtitle: this.$t('seller.checkouts.text_2771'), img: "tema0" },
        { id: 8, title: this.$t('seller.checkouts.text_2758'), subtitle: this.$t('seller.checkouts.text_2772'), img: "tema1" },
        { id: 9, title: this.$t('seller.checkouts.text_2759'), subtitle: this.$t('seller.checkouts.text_2773'), img: "tema0" },
        { id: 10, title: this.$t('seller.checkouts.text_2760'), subtitle: this.$t('seller.checkouts.text_2774'), img: "tema2" },
        { id: 11, title: this.$t('seller.checkouts.text_2762'), subtitle: this.$t('seller.checkouts.text_2763'), img: "tema3" },
        { id: 12, title: this.$t('seller.checkouts.text_2761'), subtitle: this.$t('seller.checkouts.text_2775'), img: "tema4" },
        { id: 13, title: this.$t('seller.checkouts.text_2754'), subtitle: this.$t('seller.checkouts.text_2776'), img: "tema1" },
        { id: 14, title: this.$t('seller.checkouts.text_2754'), subtitle: this.$t('seller.checkouts.text_2777'), img: "tema1" },
        { id: 15, title: this.$t('seller.checkouts.text_2754'), subtitle: this.$t('seller.checkouts.text_2778'), img: "tema1" },
      ],
      ob_custom: false,
      ob_title: this.$t('seller.checkouts.text_178'),
      ob_subtitle: this.$t('seller.checkouts.text_1743'),
      ob_description: true,
      ob_border: "off",
      ob_border_px: 2,
      ob_background_color: {
        color: "#FF0C37",
        background: "rgba(255, 12, 55, 0.1)",
      },
      checkbox: false,
      scarcity: false,
      scarcity_title: "",
      scarcity_subtitle: "",
      scarcity_time: "",
      scarcity_background_color: "#00AF7B",
      button_text: "",
      jivochat_id: "",
      zopim_id: "",
      trial_position: "top",
      step_checkout: "default",
      notification: "off",
      warranty_checkout: "off",
      notification_type: "quantity",
      notification_quantity: null,
      notification_interval: ["15", "50"],
      notification_position: "b-toaster-top-right",
      trial_info: "total",
      tema_active: 0,
      checkout_name: "",
      cor_fundo: "light",
      color: this.isHeaven
        ? { id: 8, color: "#00E4A0", background: "rgba(0, 228, 160, 0.3)" }
        : { color: "#3483FA", background: "rgba(52, 131, 250, 0.3)" },
      whatsapp: "",
      whatsapp_number: "",
      whatsapp_msg: "",
      banner_sup: [],
      banner_rod: [],
      banner_lat: [],
      banner_lat_mobile: [],
      logotipo: [],
      checkouts: [],
      colors: [
        { color: "#2133D2", background: "rgba(33, 51, 210, 0.3)" },
        { color: "#FF0C37", background: "rgba(255, 12, 55, 0.3)" },
        { color: "#FFCB37", background: "rgba(255, 203, 55, 0.3)" },
        { color: "#2ED7EE", background: "rgba(46, 215, 238, 0.3)" },
        { color: "#5D21D2", background: "rgba(93, 33, 210, 0.3)" },
        { color: "#17191F", background: "rgba(0, 9, 22, 0.3)" },
        { color: "#3483FA", background: "rgba(52, 131, 250, 0.3)" },
        { color: "#00AF7B", background: "rgba(0, 175, 123, 0.3)" },
        { color: "#00E4A0", background: "rgba(0, 228, 160, 0.3)" },
      ],
      colors2: [
        { color: "#FF0C37", background: "rgba(255, 12, 55, 0.1)" },
        { color: "#2133D2", background: "rgba(33, 51, 210, 0.1)" },
        { color: "#FFCB37", background: "rgba(255, 203, 55, 0.1)" },
        { color: "#2ED7EE", background: "rgba(46, 215, 238, 0.1)" },
        { color: "#5D21D2", background: "rgba(93, 33, 210, 0.1)" },
        { color: "#17191F", background: "rgba(0, 9, 22, 0.1)" },
        { color: "#3483FA", background: "rgba(52, 131, 250, 0.1)" },
        { color: "#00AF7B", background: "rgba(0, 175, 123, 0.1)" },
        { color: "#00E4A0", background: "rgba(0, 228, 160, 0.1)" },
      ],
      maxLength: 48,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      translations_pt: {
        countrySelectorLabel: "Código do país",
        countrySelectorError: "Escolha um país",
        phoneNumberLabel: "Número de Telefone",
        example: "Exemplo: ",
      },
      translations_en: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Example: ",
      },
      translations_es: {
        countrySelectorLabel: "Código de país",
        countrySelectorError: "Elige un país",
        phoneNumberLabel: "Número de teléfono",
        example: "Ejemplo: ",
      },
      navigator: navigator,
      wpp_country: "",
      confirmation_email: false,
      exit_pop_up: false,
      image_exit_pop_up: [],
      button_exit_pop_up: false,
      text_button_exit_pop_up: '',
      link_exit_pop_up: '', 
      description_exit_pop_up: '',
      title_exit_pop_up: '',
      urlPattern: /^(ftp|http|https):\/\/[^ "]+$/,
      linkExitPopUpChanged: false,
      descriptionExitPopUpLength: 0,
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY HH:ss");
    },
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    baseURL() {
      return this.isHeaven ? process.env.VUE_SALES_HEAVEN_PAGE : process.env.VUE_SALES_PAGE;
    },
    baseAdmURL() {
      return this.isHeaven ? process.env.VUE_ADM_HEAVEN_PAGE : process.env.VUE_ADM_PAGE;
    },
    username() {
      return this.$store.getters.getProfile.username;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    salesPage() {
      let r = "";
      for (var i = 0; i < this.links.length; i++)
        if (this.links[i].name == "MAIN") {
          r = this.baseURL + "/redirect/" + this.links[i].id;
        }
      return r;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    isLinkValid() {
      return this.urlPattern.test(this.link_exit_pop_up);
    },
  },
  watch: {
    isMobile(value) {
      if (value) {
        this.maxLength = 33;
      } else this.maxLength = 48;
    },
    description_exit_pop_up() {
      this.updateDescriptionExitPopUpLength();
    }
  },
  methods: {
    notificationON(position) {
      this.notification_position = position;
      this.notification = "on";
      this.$bvToast.toast(`{Cliente} ${this.$t('seller.checkouts.text_200')}`, {
        title: this.$t('seller.checkouts.text_201'),
        variant: "success",
        toaster: position,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    selectColorEscassez(data) {
      this.scarcity_background_color = data.color;
    },
    imageItem(pos) {
      const img = require(`@/assets/img/trial/${pos}.png`);
      return `<img src="${img}"/>`;
    },
    editCheckout(id) {
      this.loading = true;
      service
        .read({ id: id })
        .then((response) => {
          this.config = true;

          this.edit = response.checkout.id;
          this.checkout_name = response.checkout.title;
          if (response.metas.theme !== null) {
            this.cor_fundo = response.metas.theme;
          }
          if (response.metas.top_thumb !== "") {
            this.banner_sup = [{ path: response.metas.top_thumb }];
          }
          if (response.metas.bottom_thumb !== "") {
            this.banner_rod = [{ path: response.metas.bottom_thumb }];
          }
          if (response.metas.side_thumb !== "") {
            this.banner_lat = [{ path: response.metas.side_thumb }];
          }
          
          if (
            response.metas.side_thumb_mobile === null ||
            response.metas.side_thumb_mobile === ""
          ) {
            this.banner_lat_mobile = [];
          } else {
            this.banner_lat_mobile = [
              { path: response.metas.side_thumb_mobile },
            ];
          }

          if (response.metas.logotipo !== "") {
            this.logotipo = [{ path: response.metas.logotipo }];
          }
          this.color.color = response.metas.theme_color;
          this.color.background = response.metas.theme_color_bg;

          this.step_checkout = response.metas.step_checkout || "default";

          this.warranty_checkout = response.metas.warranty_checkout || "off";
          // escassez
          this.scarcity =
            response.metas.scarcity == "on" ? true : false || false;
          this.scarcity_title = response.metas.scarcity_title || "";
          this.scarcity_subtitle = response.metas.scarcity_subtitle || "";
          this.scarcity_time = response.metas.scarcity_time || "0";
          this.scarcity_background_color =
            response.metas.scarcity_background_color || "#00AF7B";

          if (response.metas.purchase_notification !== "") {
            this.notification = response.metas.purchase_notification;
          }
          if (response.metas.how_get_purchase_notification !== "") {
            this.notification_type =
              response.metas.how_get_purchase_notification || "quantity";
          }
          if (response.metas.quantity_purchase_notification !== "") {
            this.notification_quantity =
              response.metas.quantity_purchase_notification;
          }
          if (response.metas.type_purchase_notification !== "") {
            this.notification_position =
              response.metas.type_purchase_notification;
          }
          if (response.metas.minimum_purchase_notification_interval !== "") {
            this.notification_interval[0] =
              response.metas.minimum_purchase_notification_interval;
          }
          if (response.metas.maximum_purchase_notification_interval !== "") {
            this.notification_interval[1] =
              response.metas.maximum_purchase_notification_interval;
          }
          if (response.metas.ob_custom) {
            this.ob_custom = response.metas.ob_custom;
          }

          if (response.metas.whatsapp_button) {
            this.whatsapp_button = response.metas.whatsapp_button == "1" ? true : false;
          }

          if (response.metas.ob_title) {
            this.ob_title = response.metas.ob_title;
          }

          if (response.metas.ob_subtitle) {
            this.ob_subtitle = response.metas.ob_subtitle;
          }

          if (response.metas.ob_description) {
            this.ob_description = response.metas.ob_description;
          }

          if (response.metas.ob_border) {
            this.ob_border = response.metas.ob_border;
          }

          if (response.metas.ob_border_px) {
            this.ob_border_px = response.metas.ob_border_px;
          }

          if (response.metas.zopim_id) {
            this.zopim_id = response.metas.zopim_id;
          }

          if (response.metas.ob_background_color) {
            this.ob_background_color = JSON.parse(
              response.metas.ob_background_color
            );
          }

          if (response.metas.whatsapp_number) {
            this.wpp_country = response.metas.whatsapp_number.split('.')[0]
            this.whatsapp_number = response.metas.whatsapp_number;
            this.whatsapp = response.metas.whatsapp_number.split('.')[2];
          }
          if (response.metas.whatsapp_msg) {
            this.whatsapp_msg = response.metas.whatsapp_msg;
          }
          if (response.metas.confirmation_email) {
            this.confirmation_email = response.metas.confirmation_email === "on";
          }
          if (response.metas.exit_pop_up) {
            this.exit_pop_up = response.metas.exit_pop_up === "on";
          }
          if (response.metas.image_exit_pop_up !== "") {
            this.image_exit_pop_up = [{ path: response.metas.image_exit_pop_up }];
          }
          if (response.metas.title_exit_pop_up) {
            this.title_exit_pop_up  = response.metas.title_exit_pop_up;
          }
          if (response.metas.text_button_exit_pop_up) {
            this.text_button_exit_pop_up  = response.metas.text_button_exit_pop_up;
          }
          if (response.metas.button_exit_pop_up) {
            this.button_exit_pop_up  = response.metas.button_exit_pop_up  === "on";
          }
          if (response.metas.link_exit_pop_up) {
            this.link_exit_pop_up = response.metas.link_exit_pop_up;
          }
          if (response.metas.description_exit_pop_up) {
            this.description_exit_pop_up = response.metas.description_exit_pop_up;
          }

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    deleteCheckout(id) {
      this.loading = true;
      service
        .destroy(id)
        .then(() => {
          this.$bvToast.toast(this.$t('seller.checkouts.text_202'), {
            title: "Checkout",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.loading = false;
          this.fetchCheckouts();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    uploadImageSuccessBL(formData, index, fileList) {
      this.banner_lat = fileList;
    },
    uploadImageSuccessBLM(formData, index, fileList) {
      this.banner_lat_mobile = fileList;
    },
    beforeRemoveBL(index, done, fileList) {
      var r = confirm(this.$t('seller.checkouts.text_1744'));
      if (r == true) {
        this.banner_lat = [];
        done();
      }
    },
    beforeRemoveBLM(index, done, fileList) {
      var r = confirm(this.$t('seller.checkouts.text_1744'));
      if (r == true) {
        this.banner_lat_mobile = [];
        done();
      }
    },
    editImageBL(formData, index, fileList) {
      this.banner_lat = fileList;
    },
     editImageBLM(formData, index, fileList) {
      this.banner_lat_mobile = fileList;
    },

    uploadImageSuccessBR(formData, index, fileList) {
      this.banner_rod = fileList;
    },
    beforeRemoveBR(index, done, fileList) {
      var r = confirm(this.$t('seller.checkouts.text_1744'));
      if (r == true) {
        this.banner_rod = [];
        done();
      }
    },
    editImageBR(formData, index, fileList) {
      this.banner_rod = fileList;
    },
    uploadImageSuccessBS(formData, index, fileList) {
      this.banner_sup = fileList;
    },
    beforeRemoveBS(index, done, fileList) {
      var r = confirm(this.$t('seller.checkouts.text_1744'));
      if (r == true) {
        this.banner_sup = [];
        done();
      }
    },
    editImageBS(formData, index, fileList) {
      this.banner_sup = fileList;
    },
    uploadImageSuccess(formData, index, fileList) {
      this.logotipo = fileList;
    },
    uploadImageSuccessExitPopUp(formData, index, fileList) {
      this.image_exit_pop_up = fileList;
    },
    beforeRemoveExitPopUp(index, done, fileList) {
      var r = confirm(this.$t('seller.checkouts.text_1744'));
      if (r == true) {
        this.image_exit_pop_up = [];
        done();
      }
    },
    editImageExitPopUp(formData, index, fileList) {
      this.image_exit_pop_up = fileList;
    },
    beforeRemove(index, done, fileList) {
      var r = confirm(this.$t('seller.checkouts.text_1744'));
      if (r == true) {
        this.logotipo = [];
        done();
      }
    },
    editImage(formData, index, fileList) {
      this.logotipo = fileList;
    },
    cancel() {
      this.config = false;
      this.tema_active = 0;
      this.checkout_name = "";
      this.cor_fundo = "light";
      this.color = { color: "#009488", background: "rgba(0, 228, 160, 0.3)" };
      this.whatsapp_number = "";
      this.whatsapp_msg = "";
      this.confirmation_email = false;
      this.banner_sup = [];
      this.banner_rod = [];
      this.banner_lat = [];
      this.banner_lat_mobile = [];
      this.logotipo = [];
      this.exit_pop_up = false;
      this.image_exit_pop_up = [];
      this.button_exit_pop_up = false;
      this.text_button_exit_pop_up = '';
      this.link_exit_pop_up = '';
      this.description_exit_pop_up = '';
      this.title_exit_pop_up = '';
      this.edit = null;
    },
    // update
    onSubmitUpdate() {
        if (!this.isLinkValid && this.exit_pop_up) {
            this.$bvToast.toast(this.$t('seller.checkouts.text_2796'), {
            title: "URL",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        } 
        if(this.image_exit_pop_up.length == 0 && !this.button_exit_pop_up && this.exit_pop_up){
            this.$bvToast.toast(this.$t('seller.checkouts.text_2797'), {
            title: this.$t('seller.checkouts.text_2798'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
        if(this.button_exit_pop_up && this.text_button_exit_pop_up == '' && this.exit_pop_up){
            this.$bvToast.toast(this.$t('seller.checkouts.text_2799'), {
            title: this.$t('seller.checkouts.text_2800'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
        if (!this.results_tel.isValid && this.whatsapp_button) {
            this.$bvToast.toast(this.$t('seller.checkouts.text_1739'), {
            title: this.$t('seller.checkouts.text_2776'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        } 
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          let data = {
            theme: this.cor_fundo,
            theme_color: this.color.color,
            theme_color_bg: this.color.background,
            top_thumb: this.banner_sup,
            bottom_thumb: this.banner_rod,
            side_thumb: this.banner_lat,
            side_thumb_mobile: this.banner_lat_mobile,
            logotipo: this.logotipo,
            button_text: this.button_text,
            trial_position: this.trial_position,
            step_checkout: this.step_checkout,
            trial_info: this.trial_info,
            jivochat_id: this.jivochat_id,
            zopim_id: this.zopim_id,
            purchase_notification: this.notification,
            how_get_purchase_notification: this.notification_type,
            quantity_purchase_notification: this.notification_quantity,
            minimum_purchase_notification_interval:
              this.notification_interval[0],
            maximum_purchase_notification_interval:
              this.notification_interval[1],
            type_purchase_notification: this.notification_position,
            whatsapp_number: this.whatsapp_number,
            whatsapp_msg: this.whatsapp_msg,
            confirmation_email: this.confirmation_email ? "on" : "off",
            exit_pop_up: this.exit_pop_up  ? "on" : "off",
            title_exit_pop_up: this.title_exit_pop_up,
            image_exit_pop_up: this.image_exit_pop_up,
            button_exit_pop_up: this.button_exit_pop_up  ? "on" : "off",
            text_button_exit_pop_up: this.text_button_exit_pop_up,
            link_exit_pop_up: this.link_exit_pop_up,
            description_exit_pop_up: this.description_exit_pop_up,
            warranty_checkout: this.warranty_checkout,
            // escassez
            scarcity: this.scarcity ? "on" : "off",
            scarcity_title: this.scarcity_title,
            scarcity_subtitle: this.scarcity_subtitle,
            scarcity_time: this.scarcity_time,
            scarcity_background_color: this.scarcity_background_color,
            ob_custom: this.ob_custom,
            whatsapp_button: this.whatsapp_button,
            ob_title: this.ob_title,
            ob_subtitle: this.ob_subtitle,
            ob_description: this.ob_description,
            ob_border: this.ob_border,
            ob_border_px: this.ob_border_px,
            ob_background_color: JSON.stringify(this.ob_background_color),
          };
          this.loading = true;

          service
            .createId({ id: this.edit, title: this.checkout_name })
            .then((response) => {
              this.submitMetas(response.id, data);
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        }
      });
    },
    onSubmit() {
      this.submit = true;
      if (!this.isLinkValid && this.exit_pop_up) {
            this.$bvToast.toast(this.$t('seller.checkouts.text_2796'), {
            title: "URL",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        } 
        if(this.image_exit_pop_up.length == 0 && !this.button_exit_pop_up && this.exit_pop_up){
            this.$bvToast.toast(this.$t('seller.checkouts.text_2797'), {
            title: this.$t('seller.checkouts.text_2798'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
        if(this.button_exit_pop_up && this.text_button_exit_pop_up == '' && this.exit_pop_up){
            this.$bvToast.toast(this.$t('seller.checkouts.text_2799'), {
            title: this.$t('seller.checkouts.text_2800'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
        if (!this.results_tel.isValid && this.whatsapp_button) {
            this.$bvToast.toast(this.$t('seller.checkouts.text_1739'), {
            title: this.$t('seller.checkouts.text_2776'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        } 

      let data = {
        theme: this.cor_fundo,
        theme_color: this.color.color,
        theme_color_bg: this.color.background,
        top_thumb: this.banner_sup,
        bottom_thumb: this.banner_rod,
        side_thumb: this.banner_lat,
        side_thumb_mobile: this.banner_lat_mobile,
        logotipo: this.logotipo,
        button_text: this.button_text,
        trial_position: this.trial_position,
        step_checkout: this.step_checkout,
        trial_info: this.trial_info,
        jivochat_id: this.jivochat_id,
        zopim_id: this.zopim_id,
        purchase_notification: this.notification,
        how_get_purchase_notification: this.notification_type,
        quantity_purchase_notification: this.notification_quantity,
        minimum_purchase_notification_interval: this.notification_interval[0],
        maximum_purchase_notification_interval: this.notification_interval[1],
        type_purchase_notification: this.notification_position,
        warranty_checkout: this.warranty_checkout,
        scarcity: this.scarcity ? "on" : "off",
        whatsapp_number: this.whatsapp_number,
        whatsapp_msg: this.whatsapp_msg,
        confirmation_email: this.confirmation_email ? "on" : "off",
        exit_pop_up : this.exit_pop_up  ? "on" : "off",
        title_exit_pop_up : this.title_exit_pop_up,
        image_exit_pop_up : this.image_exit_pop_up,
        button_exit_pop_up : this.button_exit_pop_up  ? "on" : "off",
        text_button_exit_pop_up : this.text_button_exit_pop_up,
        link_exit_pop_up : this.link_exit_pop_up,
        description_exit_pop_up : this.description_exit_pop_up,
        scarcity_title: this.scarcity_title,
        scarcity_subtitle: this.scarcity_subtitle,
        scarcity_time: this.scarcity_time,
        scarcity_background_color: this.scarcity_background_color,
        ob_custom: this.ob_custom,
        whatsapp_button: this.whatsapp_button,
        ob_title: this.ob_title,
        ob_subtitle: this.ob_subtitle,
        ob_description: this.ob_description,
        ob_border: this.ob_border,
        ob_border_px: this.ob_border_px,
        ob_background_color: JSON.stringify(this.ob_background_color),
      };

      this.loading = true;
      service
        .create({ title: this.checkout_name })
        .then((response) => {
          this.submitMetas(response.id, data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    submitMetas(id, data) {
      if (id === undefined) {
        id = this.edit;
      }
      if (data.image_exit_pop_up.length == 0 || data.image_exit_pop_up == null || data.image_exit_pop_up[0].path == null) {
        data.image_exit_pop_up = '';
      } else {
        data.image_exit_pop_up = data.image_exit_pop_up[0].path;
      }
      
      data.top_thumb = 
        data.top_thumb.length !== 1 ? "" : data.top_thumb[0].path.toString();

      data.bottom_thumb = 
        data.bottom_thumb.length !== 1 ? "" : data.bottom_thumb[0].path.toString();

      data.side_thumb = 
        data.side_thumb.length !== 1 ? "" : data.side_thumb[0].path.toString();
      
      data.side_thumb_mobile = 
        data.side_thumb_mobile.length !== 1 ? "" : data.side_thumb_mobile[0].path.toString();
      
      data.logotipo = 
        data.logotipo.length !== 1 ? "" : data.logotipo[0].path.toString();

      data.id = id + "/meta";
      service
        .createId(data)
        .then(() => {
          this.$bvToast.toast(this.$t('seller.checkouts.text_203'), {
            title: "Checkout",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.cancel();
          this.fetchCheckouts();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    selectColor(data) {
      this.color = data;
    },

    changeBackground(theme) {
      this.cor_fundo = theme;
    },
    activeTheme(data) {
      this.tema_active = data.id;
    },
    openConfig(type) {
      this.zopim_id = "";
      this.whatsapp_button = false;
      this.whatsapp_number = "";
      this.whatsapp = "";
      if (type === "checkout") {
        this.config = true;
      } else if (type === "upsell") {
        this.configUpsell = true;
      }
    },
    handleResize() {
      if(this.client.width === 0) this.client.width = window.innerWidth;
      if(this.client.width > 0) this.client.width = window.screen.width;
    },
    fetchCheckouts() {

      this.loading = true;

      service
        .search()
        .then((response) => {
          this.checkouts = response;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * On whatsapp number update, set whatsapp_number in formatt:
     * Country abbreviation.Country code.Mobile number.
     *
     * Ex: BR.55.31991234567
     *
     * @returns no return avaliable
     * @param Object payload of lib vue-phone-number-input
     * @author Sandro Souza <sandro@greenn.com.br>
     */
    onUpdate(payload) {
      let country_code = payload.countryCode;
      let countryCallingCode = payload.countryCallingCode;
      let phone_number = payload.nationalNumber;
      let result = `${country_code}.${countryCallingCode}.${phone_number}`;

      this.whatsapp_number = result;
      this.results_tel = payload;
    },
    updateDescriptionExitPopUpLength() {
      this.descriptionExitPopUpLength = this.description_exit_pop_up.length;
    }
  },
  mounted() {
    this.fetchCheckouts();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss">
.vue-slider-process {
  background-color: #009488 !important;
}
.vue-slider-dot-handle {
  background-color: #009488 !important;
}
.vue-slider-rail {
  background-color: #c1f1e3 !important;
}
.vue-slider-dot-tooltip-inner {
  background-color: #009488 !important;
}
.vue-slider-dot-handle::after {
  background-color: #c1f1e3 !important;
}
</style>

<style lang="scss">
.not_padding {
  padding: 0 !important;
}
.order-bump .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff !important;
  border-color: #ffffff !important;
  background-color: #ffffff !important;
}

.order-bump
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.564 0.75L2.974 4.362L1.436 2.812L0 4.26L2.974 7.25L8 2.193L6.564 0.75Z' fill='black'/%3E%3C/svg%3E%0A");
}

.country-selector__toggle {
  padding-top: 5px !important;
}

.country-selector__input, .input-tel__input {
  padding-top: 25px !important;
  padding-left: 40px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: 0.01em !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-variant-ligatures: normal !important;
  font-variant-caps: normal !important;
  font-variant-numeric: normal !important;
  font-variant-east-asian: normal !important;
}

.country-selector__label, .input-tel__label {
  font-size: 11px !important;
  font-weight: normal !important;
  color: #333333 !important;
  line-height: 1.333rem;
  font-family: "Montserrat", sans-serif !important;
}
</style>

<style scoped lang="scss">
.order-bump {
  width: 100%;
  cursor: pointer;
}

.order-bump .header {
  height: 51px;
  width: 100%;
  background: #2133d2;
  border-radius: 5px 5px 0px 0px;
  padding: 0px 32px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  user-select: none;
}

.order-bump .header .info-checkbox {
  color: white;
  letter-spacing: 0.2px;
  font-size: 14px;
  font-weight: 600;
}

.order-bump .header .info-value {
  color: white;
  letter-spacing: 0.2px;
  font-size: 14px;
  font-weight: 600;
}

.order-bump .base {
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  padding: 25px 32px;
  display: grid;
  grid-template-columns: 90px 1fr;
  gap: 30px;
  pointer-events: none;
  user-select: none;
}

.order-bump .base .img {
  width: 90px;
  height: 90px;
  border-radius: 5px;
}

.order-bump .base .img img {
  object-fit: cover;
  border-radius: 5px;
}

.order-bump .base .description {
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2px;
}

.order-bump .base .name {
  font-weight: 600;
  font-size: 14px;
  margin: 0 0 10px 0;
}

.order-bump .base .value {
  font-weight: 600;
  font-size: 14px;
  color: #2133d2;
  margin: 0 0 15px 0;
}

.checkout {
  background: #ffffff;
  box-shadow: 2px 4px 10px rgba(237, 237, 240, 0.2);
  border-radius: 10px;
  height: 398.96px;
}

.thumb {
  height: 275px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  background: #f7f7f7;
  border-radius: 10px;
  width: 97%;
  height: 135px;
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  margin: auto;
}

.info p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
  margin-bottom: 5px;
}

.info span {
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
}

.info .btn {
  width: 40px;
  height: 40px;
  background: #ededf0;
  box-shadow: 0px 2px 3px rgba(237, 237, 240, 0.3);
  border-radius: 10px;
}

.checkout-config {
  height: 101px;
  box-sizing: border-box;
}

.checkout-config span {
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px 28px;
  border-radius: 5px;
  cursor: pointer;
  background: #ffffff;
}

.checkout-config .active {
  background: #333333;
}

.checkout-config .active p {
  color: #ffffff;
}

.checkout-config .active img {
  filter: invert(100%);
}

.checkout-config p {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-bottom: 5px;
  margin-left: 23px;
}

.container-cor-fundo {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px 25px;
  cursor: pointer;
}

.container-cor-fundo p {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}

.container-cor-fundo span {
  font-weight: normal;
  font-size: 13px;
  color: #81858e;
}

.container-cor-fundo .block {
  background: rgba(51, 51, 51, 0.05);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.container-cor-fundo .block div {
  width: 10px;
  height: 10px;
  background: #333333;
  border-radius: 100%;
}

.container-cor-fundo .block2 {
  /* background: #17191F; */
  background: rgba(255, 255, 255, 0.1);
}

.container-cor-fundo .block2 div {
  background: #fff;
}

.title-config {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}
.title-config.destaque {
  color: #2133d2;
}
.container-cor-fundo.active-back {
  background: #333;
}

.container-cor-fundo.active-back p {
  color: #fff;
}

.container-cor-fundo.active-back .block {
  background: #f4f4f4;
}

.container-cor-fundo .active-back .block div {
  background: #fff;
}

.container-cor-fundo.active-back .block2 {
  background: #17191f;
}

.config-color .color {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.config-color p {
  margin-top: 20px;
  margin-bottom: 0px;
}

.config-color .color span {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.config-color .gap {
  gap: 0 20px;
}

.footer {
  margin-top: 90px;
  margin-bottom: 90px;
}
.anime {
  transition: 0.3s;
}
.anime:hover {
  transform: scale(1.07);
}

.btn-more-check {
  background: rgba(0, 228, 160, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19px 15px;
  border-radius: 5px;
}
.btn-more-check.heaven {
  background: $base-color-heaven-light;
}
.tema-escuro-bg {
  background: #17191f;
}
.color-white {
  color: #fff !important;
}
.active-item {
  position: relative;
}
.active-item::before {
  content: "✔️";
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
}
.recomendado {
  margin: 20px 0;
}
.recomendado p {
  margin: 0;
  color: #81858e;
  font-size: 14px;
}
.recomendado p + p {
  margin-top: 10px;
}
.exemplo-btn p {
  color: #81858e;
  font-size: 14px;
}
.badge-success {
  padding: 10px 15px;
  font-weight: 600;
  background-color: rgba(65, 137, 230, 0.15);
  color: #3483fa;
  white-space: pre-line;
  line-height: 1.5;
  text-align: left;
}
.exemplo-escassez {
  /* background: #3483fa; */
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
}
.exemplo-escassez h5,
.exemplo-escassez p {
  color: #fff;
  margin: 0;
}
.exemplo-escassez h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.exemplo-escassez .timer {
  font-size: 22px;
  letter-spacing: 3px;
}
.exemplo-escassez p {
  font-size: 15px;
  max-width: 300px;
}
.flex-escassez {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

#whatsapp_msg {
  height: 140px !important;
}

.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>
